import { buildApiUrl, Type, sendPatchRequest } from "../api";

type MeterConfig = {
    id: number,
    hide: boolean
  }

export async function updateMeterConfiguration(assetCode: string, meterConfigurations: MeterConfig []){
    const url = buildApiUrl(Type.AssetManagement, `asset/${assetCode}/meters/configurations`)
    await sendPatchRequest(url,{ data: meterConfigurations })
}