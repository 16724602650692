<script setup lang="ts">
import { ref, watch, computed, reactive } from 'vue';
import { useAssetSettings, useAssetSettingsLookups } from '@/composables/assets/assets';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import Card from "@/components/Card/card-container.vue";
import CardContent from "@/components/Card/card-content.vue";
import CardHeader from "@/components/Card/card-header.vue";
import CardHeaderItem from "@/components/Card/card-header-item.vue";
import InputSelect from "@/components/input-select.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import InputDate from "@/components/input-date.vue";
import InputNumber from "@/components/input-number.vue";
import InputCheckbox from "@/components/input-checkbox.vue";
import GridContainer from "@/components/grid-container.vue";
import GridItem from "@/components/grid-item.vue";
import InputLabel from "@/components/input-label.vue";
import ContentLoader from "@/components/content-loader.vue";
import { type BillingSettings } from "@/features/assets/schemas";
import { useProjectTags } from '@/composables/assets/customers'
import InputToggleGroup from '@/components/input-toggle-group.vue'
import { MeterBillingType } from '@/features/assets/constants'
const props = defineProps<{ assetCode: string }>();
const emit = defineEmits<{
  (event: 'update:autoApprove', autoApprove: boolean): void
}>();
const { data: assetData, isLoading: asseetLoading } = useAssetSettings(props.assetCode);
const { data: lookups, isLoading: lookupsLoading } = useAssetSettingsLookups();
const { data: projectTags, isLoading: projectTagsAreLoading } = useProjectTags(() => assetData.value?.invoiceDetails.contractFundEntity?.id)
var data = reactive<BillingSettings>({
  customerTag: null,
  contractStartDate: null,
  gridExportValidation: false,
  autoApprove: false,
  billingCycle: null,
  expectedEnergyVariance: 0,
  exportPower: false,
  firstEscalationMonth: null,
  invoiceDate: 0,
  paymentDueInDays: 0,
  exportPowerTariffInCents: 0,
  fundTransferDate: null,
  meterBillingType: MeterBillingType.Actual,
  invoiceDateFormat: null
});
const firstEscalationMonth = ref<number>();
const firstEscalationYear = ref<number>();
const billingCycleId = ref<number>();
const isLoading = computed(() => asseetLoading.value || lookupsLoading.value)

watch(assetData, (newAssetData) => {
  if (newAssetData?.billingSettings === undefined) {
    return
  }
  Object.assign(data, newAssetData.billingSettings);
  firstEscalationMonth.value = new Date(newAssetData.billingSettings?.firstEscalationMonth ?? "").getMonth() + 1;
  firstEscalationYear.value = new Date(newAssetData.billingSettings?.firstEscalationMonth ?? "").getFullYear();
  billingCycleId.value = newAssetData.billingSettings?.billingCycle?.id
}, { immediate: true });

watch([firstEscalationMonth, firstEscalationYear], ([newMonth, newYear]) => {
  if (newMonth !== undefined && newYear !== undefined && data) {
    data.firstEscalationMonth = new Date(newYear, newMonth, 1);
  }
}, { immediate: true });

watch(() => data.autoApprove, newValue => emit('update:autoApprove', newValue!), { immediate: true })

const years = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 6;
  const endYear = currentYear + 10;
  return Array.from({ length: endYear - startYear + 1 }, (_, index) => {
    const year = startYear + index;
    return { id: year, name: year.toString() };
  });
}

const months = () => {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return monthNames.map((name, index) => ({ id: index + 1, name }));
}
</script>
<template>
  <Card class="billing-settings-card">
    <CardHeader>
      <CardHeaderItem>Billing Settings</CardHeaderItem>
    </CardHeader>
    <CardContent>
      <ContentLoader :loading="isLoading" variant="overlay">
        <GridContainer :columns="[184, '1fr']" :column-gap="20" :class="'card-base-grid'" :row-gap="10"
          :align="'center'">

          <GridItem>
            <InputLabel>Linked Meters for Invoicing</InputLabel>
          </GridItem>
          <GridItem>
            <InputToggleGroup
              name="billingSettings.meterBillingType"
              :options="[{ text: 'Actual', value: MeterBillingType.Actual }, { text: 'Virtual', value: MeterBillingType.Virtual }]"
              :selected="data.meterBillingType"
            />
          </GridItem>

          <GridItem>
            <InputLabel>Customer Tag</InputLabel>
          </GridItem>
          <GridItem>
            <InputSelect name="billingSettings.customerTag"
                         :items="projectTags?.options.map(tag => ({ text: tag.name, value: tag.id })) ?? []"
                         item-text="text"
                         item-value="value"
                         searchable
                         required
                         :selected="data.customerTag"
                         error-variant="tooltip"
                         :placeholder="projectTagsAreLoading ? 'Loading...' : undefined" />
          </GridItem>

          <GridItem>
            <InputLabel>
              Contract Start Date
              <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle"
                v-tooltip="`The date from which<br>the contract or the<br>agreement term starts.`" />
            </InputLabel>
          </GridItem>
          <GridItem>
            <InputDate
              placeholder="Date"
              required
              :value="data.contractStartDate" :name="'billingSettings.contractStartDate'"
              error-variant="tooltip" />
          </GridItem>

          <GridItem>
            <InputLabel>
              Fund Transfer Date
              <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle"
                v-tooltip="`The date on which the asset<br>has been transferred to a<br>separate special-purpose<br>vehicle or fund (if applicable).`" />
            </InputLabel>
          </GridItem>
          <GridItem>
            <InputDate placeholder="Date" :value="data.fundTransferDate" :name="'billingSettings.fundTransferDate'"
              required
              error-variant="tooltip" />
          </GridItem>

          <GridItem>
            <InputLabel>Invoice Date Format</InputLabel>
          </GridItem>
          <GridItem>
            <InputSelect type="text" placeholder="Please select" item-text="name" item-value="name"
                         name="billingSettings.invoiceDateFormat"
               :items="lookups?.invoiceDateFormats || []" :selected="data?.invoiceDateFormat"
              error-variant="tooltip" />
          </GridItem>

          <GridItem>
            <InputLabel>Billing Cycle</InputLabel>
            <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle" v-tooltip="`Choose the billing period<br />
            for the customer. Results<br />
            span from the 1st to the<br />
            last day of the month.`" />
          </GridItem>
          <GridItem>
            <InputSelect type="text" placeholder="Please select" item-text="name" item-value="id"
                         name="billingSettings.billingCycle.id"
              :disabled="true" :items="lookups?.billingCycles || []" :selected="data?.billingCycle?.id"
              error-variant="tooltip" />
          </GridItem>
          <GridItem>
            <InputLabel>Invoice Date</InputLabel>
            <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle" v-tooltip="`The invoice is generated<br />
            on this day every month.<br />
            This will also reflect as the<br />
            Invoice Date on the Invoice.`" />
          </GridItem>
          <GridItem>
            <p>1st day of each Month</p>
          </GridItem>
          <GridItem>
            <InputLabel>Payment Due Within</InputLabel>
          </GridItem>
          <GridItem>
            <div class="d-flex gap-2 align-items-center">
              <InputNumber placeholder="0" style="width: 62px; flex: none" :value="data.paymentDueInDays"
                required :step="1" error-variant="tooltip"
                :name="'billingSettings.paymentDueInDays'" />
              Days
            </div>
          </GridItem>
          <GridItem>
            <InputLabel>First Escalation Month</InputLabel>
            <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle" v-tooltip="`Customer charges will<br />
            increase annually, starting<br />
            in this month on the 1st.`" />
          </GridItem>
          <GridItem>
            <div class="first-escalation-wrapper">
              <InputSelect
                placeholder="Select"
                item-text="name"
                item-value="id"
                :items="months()"
                v-model:selected="firstEscalationMonth"
                style="width: 100px; flex: none"
                error-variant="tooltip"
                name="billingSettings.firstEscalationMonth" />
              <InputSelect
                placeholder="Select"
                item-text="name"
                item-value="id"
                :items="years()"
                error-variant="tooltip"
                v-model:selected="firstEscalationYear"
                name="billingSettings.firstEscalationYear" />
            </div>
          </GridItem>
          <GridItem>
            <InputLabel>Auto Approve</InputLabel>
          </GridItem>
          <GridItem>
            <InputCheckbox v-model:checked="data.autoApprove" name="billingSettings.autoApprove" error-variant="tooltip" />
          </GridItem>
          <GridItem v-if="data?.autoApprove">
            <InputLabel>Expected Energy Variance</InputLabel>
            <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle" v-tooltip="`
            % variance between daily<br />
            average for the invoice period<br />
            vs daily average over the<br/>
            preceding 3 months`" />
          </GridItem>
          <GridItem v-if="data?.autoApprove">
            <InputNumber style="width: 115px; flex: none" class="medium-input input-percentage" placeholder="0"
              v-model:value="data.expectedEnergyVariance" :name="'billingSettings.expectedEnergyVariance'" error-variant="tooltip">
              <template #suffix>%</template>
            </InputNumber>
          </GridItem>
          <GridItem v-if="data?.autoApprove">
            <InputLabel>Grid Export Validation</InputLabel>
            <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle" v-tooltip="`If more than 3% of generated<br />
            solar energy was exported,<br />
            invoice will not be auto-approved.`" />
          </GridItem>
          <GridItem v-if="data?.autoApprove">
            <InputCheckbox v-model:checked="data.gridExportValidation" name="billingSettings.gridExportValidation"
              error-variant="tooltip" />
          </GridItem>
        </GridContainer>
      </ContentLoader>
    </CardContent>
  </Card>
</template>
<style scoped lang="scss">
.billing-settings-card {
  min-height: 415px;

  .card-base-grid {
    align-items: baseline;
    color: #6d6e71;
  }

  .card-input-title {
    font-size: 12px;
    font-weight: bold;
    color: #6d6e71;
  }
}

.first-escalation-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  height: 28px;
}

.medium-input {
  width: 115px;
}

.input-currency {
  :deep(.form-control) {
    text-align: right !important;
  }

  :deep(input) {
    border-left: none;
    text-align: right;
  }

  :deep(.input-group-text) {
    color: #d1d1d1;
    background-color: #fff !important;
  }

  :deep(.input-group) {
    width: unset !important;
  }
}

.input-percentage {
  :deep(.form-control) {
    text-align: left;
  }

  :deep(input) {
    border-right: none;
  }

  :deep(.input-group-text) {
    color: #d1d1d1;
    background-color: #fff !important;
  }
}

.tooltip-color {
  color: #d1d1d1;
}
</style>
