import { buildApiUrl, sendGetRequest, sendPutRequest, Type } from '@/features/api'
import { assertSchemaAndReturn } from '@/helpers/assert-schema'
import { AssetDetailsSchema, EditManualAssetSchema,type UpdateManualAsset } from '@/features/assets/schemas'

export const getAssetDetails = (assetCode: string) =>
  sendGetRequest(
    buildApiUrl(Type.AssetManagement, `v2/assets/${assetCode}`),
  ).then(
    ({ data }) => assertSchemaAndReturn(data, AssetDetailsSchema)
  )

export async function getManualAssetDetails(assetCode : string){
    const url = buildApiUrl(Type.Core, `asset/${assetCode}/manual`)
    const { data } = await sendGetRequest(url)

    return assertSchemaAndReturn(data, EditManualAssetSchema)
  }

  export async function updateManualAssetDetails(assetCode: string, manualAsset: UpdateManualAsset){
    const url = buildApiUrl(Type.AssetManagement, `asset/${assetCode}/manual`)
    await sendPutRequest(url, { data: manualAsset })
  }
