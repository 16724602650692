import { buildApiUrl, sendGetRequest, sendPutRequest, Type } from "@/features/api";
import { assertSchema, assertSchemaAndReturn } from "@/helpers/assert-schema";

import { AssetTariffSchema, TariffSchema } from "@/features/billing/schemas";
import { ExportPowerEscalationType  } from "@/features/billing/constants";
import { dateToIso } from "@/helpers/formatting";
import { roundToFractionalCurrencyPrecision, roundToMainCurrencyPrecision } from '@/helpers'

export async function getAvailableTariffs() {
  const url = buildApiUrl(Type.AssetManagement, 'lookup/asset/settings')

  const { data } = await sendGetRequest(url)

  assertSchema(data, TariffSchema)

  return data.tariffStructures.map(
    (structure) => ({
      id: structure.id,
      name: structure.name,
      types: structure.tariffTypes.map(
        ({ id, name }) => ({ id, name })
      )
    })
  )
}


export async function saveTariffsForAsset(
  assetCode: string,
  update: {
    tariffStructureId: number
    escalationDate?: Date
    escalationValue?: number
    escalationType?: ExportPowerEscalationType
    exportPower: boolean
    rates: {
      tariffTypeId: number
      pvRate: number
      exportRate?: number
    }[]
  }
) {
  const url = buildApiUrl(Type.AssetManagement, `asset/${assetCode}/billings/rates`)

  const bodyToSend: Record<string, any> = {
    tariffStructureId: update.tariffStructureId,
    exportPower: update.exportPower,
    billingRates: update.rates.map(
      rate => ({
        tariffTypeId: rate.tariffTypeId,
        rateInCents: roundToFractionalCurrencyPrecision(rate.pvRate * 100),
        exportPowerInCents: typeof rate.exportRate === 'number' ? roundToFractionalCurrencyPrecision(rate.exportRate * 100) : undefined
      })
    )
  }

  if (update.exportPower) {
    if (update.escalationDate) {
      bodyToSend.exportPowerEscDate = dateToIso(update.escalationDate)
    }

    bodyToSend.exportPowerEscValue = update.escalationValue
    bodyToSend.exportPowerEscalationTypeId = update.escalationType

    if (update.escalationType === ExportPowerEscalationType.Currency && typeof bodyToSend.exportPowerEscValue === 'number') {
      bodyToSend.exportPowerEscValue = roundToFractionalCurrencyPrecision(bodyToSend.exportPowerEscValue * 100)
    }
  }

  await sendPutRequest(url, { data: bodyToSend })
}


export async function getTariffsForAsset(assetCode: string) {
  const url = buildApiUrl(Type.AssetManagement, `assets/${assetCode}/billings/rates`)

  const { data } = await sendGetRequest(url)

  const asserted = assertSchemaAndReturn(data, AssetTariffSchema)

  return {
    tariffStructure: asserted.tariffStructureId,
    exportPower: asserted.exportPower,
    escalationDate: asserted.exportPowerEscDate,
    escalationValue: asserted.exportPowerEscalationTypeId === ExportPowerEscalationType.Currency && typeof asserted.exportPowerEscValue === 'number'
      ? roundToMainCurrencyPrecision(asserted.exportPowerEscValue / 100)
      : asserted.exportPowerEscValue,
    escalationType: asserted.exportPowerEscalationTypeId,
    billingRates: asserted.billingRates.map(rate => {
      const newRate = { ...rate }

      newRate.rateInCents = roundToMainCurrencyPrecision(newRate.rateInCents / 100)

      if (typeof newRate.exportPowerInCents === 'number') {
        newRate.exportPowerInCents = roundToMainCurrencyPrecision(newRate.exportPowerInCents / 100)
      }

      return newRate
    })
  }
}
