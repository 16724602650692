<script setup lang="ts">
import { computed, ref } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

withDefaults(
  defineProps<{
    loading: boolean
    rows?: number
    cols?: number | string[]
    tableHeader?: boolean
    variant?: 'overlay' | 'table' | 'single-line' | 'inputs'
  }>(),
  { rows: 4, cols: 6, tableHeader: true, variant: 'single-line' }
)

const $overlaid = ref<HTMLDivElement>()

const overlayStyles = computed(
  () => {
    const box = $overlaid.value?.getBoundingClientRect()

    return box ? {
      left: `${box.x + window.scrollX}px`,
      top: `${box.top + window.scrollY}px`,
      width: `${box.width}px`,
      height: `${box.height}px`
    } : undefined
  }
)

// See https://skeletonreact.com/ for a possible content loader.
</script>

<template>
  <slot v-if="!loading" />

  <div v-else-if="variant === 'overlay'" ref="$overlaid">
    <Teleport to="body">
      <div :class="$style.overlay" :style="overlayStyles">
        <FontAwesomeIcon :icon="faSpinnerThird" spin :class="$style.spinner" style="--fa-animation-duration: 1s" />
      </div>
    </Teleport>
    <slot />
  </div>

  <div v-else :class="[$style['content-loader'], $style[`variant-${variant}`]]" class="placeholder-wave">
    <template v-if="variant === 'single-line'">
      <div class="placeholder placeholder-lg" />
    </template>

    <template v-else-if="variant === 'table'">
      <div class="container-fluid">
        <div class="row" v-if="tableHeader">
          <div class="col-12">
            <div class="placeholder" />
          </div>
        </div>
        <div v-for="rowIndex in rows" class="row mt-3" :key="`row:${rowIndex}`">
          <div v-for="(colValue, colIndex) in cols"
               :key="`col:${colIndex}`"
               :style="{ width: Array.isArray(cols) ? `calc(${colValue})` : undefined }"
               :class="typeof cols === 'number' ? `col-${Math.floor(12 / cols)}` : undefined">
            <div class="placeholder" />
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="variant === 'inputs'">
      <div class="container-fluid">
        <div v-for="index in rows" class="row" :class="{ 'mt-3': index > 1 }" :key="`row:${index}`">
          <div class="col-5">
            <div class="placeholder" />
          </div>
          <div class="col-7">
            <div class="placeholder" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style module lang="scss">
@import "@/assets/variables";

.overlay {
  position: absolute;
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    font-size: clamp(1.5rem, 20cqh, 3.5rem);
  }
}

.content-loader {
  position: relative;

  :global {
    .placeholder {
      display: block;
      background-color: #e0e0e0;
      border-radius: 4px;
      cursor: default;
    }
  }
}

.variant-inputs {
  :global(.placeholder) {
    min-height: 1.65rem;
  }
}
</style>
