<script setup lang="ts">
import { ref, computed, watch } from "vue";
import InputCheckbox from "@/components/input-checkbox.vue";
import InputText from "@/components/input-text.vue";
import { useContacts, useUpdateContacts } from '@/composables/contacts/contacts';
import { type Contact } from "@/features/contacts/schemas";
import ContentLoader from "@/components/content-loader.vue";
import HeroSplashError from "@/components/hero-splash-error.vue";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import useNotifications from "@/composables/notifications";
import ModalContainer from '@/components/modal/modal-container.vue'
import ModalTitle from '@/components/modal/modal-title.vue'
import ModalContent from '@/components/modal/modal-content.vue'
import ModalFooterConfirmation from '@/components/modal/modal-footer-confirmation.vue'

const props = defineProps<{ assetCode: string; }>();
const { data: contactData, isLoading: contactLoading, error: contactError } = useContacts(props.assetCode, 11);
const { mutateAsync, isPending, reset, isError } = useUpdateContacts(props.assetCode);
const { addSuccess } = useNotifications();
const filteredContacts = ref<Contact[]>([]);
const searchText = ref('');
const emit = defineEmits({
    close: () => true,
});

const handleAccept = async () => {
    await mutateAsync(filteredContacts);
    addSuccess({ title: 'Contact(s) successfully linked.' })
    emit("close");
};

const handleReject = () => {
    reset();
    emit("close");
};
const isLoading = computed(() => contactLoading.value || isPending.value);

watch([searchText, contactData], ([search, newdata]) => {
    if (!newdata) {
        filteredContacts.value = [];
    } else if (!search) {
        filteredContacts.value = newdata.map(contact => ({ ...contact }));
    } else {
        const lowerSearch = search.toLowerCase();
        filteredContacts.value = newdata
            .map(contact => ({ ...contact }))
            .filter(({ firstname, surname, phoneNumbers, emailAddresses }: Contact) => {
                const fullName = `${firstname} ${surname}`.toLowerCase();
                const formattedContactNumber = phoneNumbers[0]?.phoneNumber.replace(/\D/g, "") || "";
                const email = emailAddresses[0]?.emailAddress.toLowerCase() || "";
                return (
                    fullName.includes(lowerSearch) ||
            formattedContactNumber.includes(lowerSearch) ||
            email.includes(lowerSearch)
            );
            });
            }
            });
            </script>


            <template>
                <ModalContainer class="native-modal-confirmation">
                  <ModalTitle>EXISTING CONTACTS</ModalTitle>
                    <ModalContent>
                        <div class="native-modal-confirmation--content">
                            <ContentLoader :loading="isLoading" variant="overlay">
                                <HeroSplashError v-if="contactError || isError" />
                                <InputText v-model:value="searchText" type="text" placeholder="Search"
                                    style="width: 136px; height: 28px; position: absolute; top: -76px; right: 14px" />
                                <table class="table table-no-bottom-border">
                                    <thead>
                                        <tr>
                                            <th scope="col">Select</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Surname</th>
                                            <th scope="col">Contact Number</th>
                                            <th scope="col">Email Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(contact, index) in filteredContacts" :key="index"
                                            :class="{ 'last-row': index === filteredContacts.length - 1 }">

                                            <td>
                                                <InputCheckbox v-model:checked="contact.linked" />
                                            </td>
                                            <th scope="row">{{ contact.firstname }}</th>
                                            <th scope="row">{{ contact.surname }}</th>
                                            <td>{{ contact.phoneNumbers[0]?.phoneNumber }}</td>
                                            <td>{{ contact.emailAddresses[0]?.emailAddress }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ContentLoader>
                        </div>
                    </ModalContent>
                  <ModalFooterConfirmation
                    confirm-text="Link"
                    :confirm-icon="faLink"
                    reject-text="Cancel"
                    @reject="handleReject"
                    @confirm="handleAccept()"
                  />
                </ModalContainer>
            </template>

            <style scoped lang="scss">
            .native-modal-confirmation {
                width: 733px;
                min-height: 300px;
                position: relative;

                .native-modal-confirmation--title {
                    margin: -25px -25px 25px -25px;
                    padding: 25px;
                    border-bottom: 1px solid #ddd;
                    font-size: 16px;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .native-modal-confirmation--content-wrapper {
                    margin-top: 25px;
                    display: flex;
                    flex: 1 auto;
                }

                .native-modal-confirmation--content {
                    width: 100%;
                    margin-top: 0;

                    &.with-icon {
                        margin-left: 25px;
                    }
                }

                .native-modal-confirmation--icon {
                    font-size: 45px;
                }

                .native-modal-confirmation--actions {
                    margin-top: 18px;
                    margin-left: 423px;

                    button {
                        width: 110px;
                        height: 31px;
                        margin-left: 10px;
                    }
                }

                .native-modal-confirm--action-confirm {
                    margin-left: 10px;
                }
            }
        </style>
