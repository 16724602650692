<!-- eslint-disable no-debugger -->
<script setup lang="ts">
import { computed } from 'vue'
import ContentLoader from '@/components/content-loader.vue'
import HeroSplashError from '@/components/hero-splash-error.vue'
import useNotifications from '@/composables/notifications'
import { useGetManualAssetDetails, useUpdateManualAsset } from '@/composables/assets/assets'
import InputLabel from '@/components/input-label.vue'
import InputSelect from '@/components/input-select.vue'
import InputNumber from '@/components/input-number.vue'
import InputText from '@/components/input-text.vue'
import GridContainer from '@/components/grid-container.vue'
import GridItem from '@/components/grid-item.vue'
import ModalContainer from '@/components/modal/modal-container.vue'
import ModalTitle from '@/components/modal/modal-title.vue'
import ModalContent from '@/components/modal/modal-content.vue'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { z } from 'zod'
import ModalFooterCancelAndSave from '@/components/modal/modal-footer-cancel-and-save.vue'

const props = defineProps<{ assetCode: string }>()
const { data: manualAsset, isLoading: lookupsLoading, isError } = useGetManualAssetDetails(props.assetCode)
const { mutateAsync, isPending } = useUpdateManualAsset(props.assetCode)
const { addSuccess, addError } = useNotifications()
const emit = defineEmits({
  close: () => true
})
const isLoading = computed(() => lookupsLoading.value)

const form = useForm({
  validationSchema: toTypedSchema(
    z.object({
      VCOMSystemKey: z.string().min(1, { message: 'VCOM System Key must not be empty' }),
      TariffEscalationId: z.number().gt(0, { message: 'Annual Escalation option must be selected' }),
      EscalationValueOffSet: z.number().gte(0, { message: 'Esclation value must not be empty' }),
      ConsumptionYield: z.number().gt(0, { message: 'Consumption Yield must be greater than 0' }),
      SystemSize: z.number().gt(0, { message: 'System Size must be greater than 0' }),
      ContractingPartyId: z.number().gt(0, { message: 'Owmner must not be empty' }),
      ProductId: z.number().gt(0, { message: 'Product must not be empty' })
    })
  )
})

const isFormValid = computed(() => form.meta.value.valid)

const handleSave = form.handleSubmit(
  async (values) => {
  try {
    await mutateAsync({
      Id: manualAsset.value!.Id,
      SolutionId: manualAsset.value!.SolutionId || 0,
      Country: manualAsset.value!.Country,
      OrganisationId: manualAsset.value!.OrganisationId,
      VatNo: manualAsset.value!.VatNo!,
      VCOMSystemKeyId: manualAsset.value!.VCOMSystemKeyId!,

      VCOMSystemKey: values.VCOMSystemKey,
      TariffEscalationId: values.TariffEscalationId,
      EscalationValueOffSet: values.EscalationValueOffSet,
      ConsumptionYield: values.ConsumptionYield,
      SystemSize: values.SystemSize,
      ContractingPartyId: values.ContractingPartyId,
      ProductId: values.ProductId
    })

    addSuccess({ title: 'Asset inputs updated successfully' })

    emit('close')
  } catch (error) {
    addError({
      title: 'Unable to update asset inputs.',
      message: 'Please try again.'
    })
  }
})
</script>

<template>
  <ModalContainer @close="$emit('close')" :width="429">
    <ModalTitle> EDIT MANUAL INPUTS </ModalTitle>
    <ModalContent>
      <ContentLoader :loading="true" v-if="isLoading" variant="table" :rows="9" :table-header="false" :cols="['122px', '100% - 122px']" />

      <HeroSplashError v-else-if="isError"
                       title="Unable to load asset details."
                       sub-title="For some reason, this asset's details could not be loaded. Please try reloading the page." />

      <GridContainer v-else :columns="[122, '1fr']" :column-gap="13">
        <InputLabel> Project Code </InputLabel>
        <span>{{ manualAsset?.ProjectLead?.ProjectLeadId }}</span>

        <InputLabel>Project Name</InputLabel>
        <span>{{ manualAsset?.ProjectLead?.ProjectName }}</span>

        <InputLabel>Project Address</InputLabel>
        <span>
          {{ [
            manualAsset?.ProjectLead?.SiteAddressLine1,
            manualAsset?.ProjectLead?.SiteAddressSuburb,
            manualAsset?.ProjectLead?.SiteAddressCity,
            manualAsset?.Country,
            manualAsset?.ProjectLead?.Province.ProvinceDescription
          ].filter(x => !!x).join(', ') }}
        </span>

        <InputLabel>Product</InputLabel>
        <InputSelect name="ProductId"
                     item-value="value"
                     item-text="text"
                     :items="(manualAsset?.Products || []).map(item => ({ text: item.label, value: parseInt(item.value, 10) }))"
                     :selected="manualAsset?.ProductId" />

        <InputLabel>Owner</InputLabel>
        <InputSelect name="ContractingPartyId"
                     item-text="text"
                     item-value="value"
                     :items="(manualAsset?.ContractFundEntities || []).map(x => ({ text: x.label, value: parseInt(x.value, 10) }))"
                     :selected="manualAsset?.ContractingPartyId" />

        <GridItem span>
          <GridContainer :columns="[122, '1fr', '0.5fr']" align="center" :column-gap="13">
            <InputLabel>System Size</InputLabel>
              <InputNumber name="SystemSize"
                           inline
                           placeholder="0.00"
                           :value="manualAsset?.SystemSize"
                           error-variant="tooltip" />
              <span class="text-muted">kWh</span>

              <InputLabel>Consumption Yield</InputLabel>
              <InputNumber name="ConsumptionYield"
                           placeholder="0.00"
                           inline
                           :value="manualAsset?.ConsumptionYield"
                           error-variant="tooltip" />
              <span class="text-muted">kWh</span>

              <InputLabel>Annual Escalation</InputLabel>
              <InputSelect name="TariffEscalationId"
                           item-text="text"
                           item-value="value"
                           :selected="manualAsset?.TariffEscalationId"
                           :items="(manualAsset?.TariffEscalationTypes || []).map(x => ({ text: x.label, value: parseInt(x.value, 10) }))" />
              <InputNumber name="EscalationValueOffSet"
                           placeholder="0.00"
                           :value="manualAsset?.EscalationValueOffSet"
                           error-variant="tooltip">
                <template #suffix>
                  <span class="text-muted">%</span>
                </template>
              </InputNumber>

              <InputLabel>VCOM System Key</InputLabel>
              <GridItem :start="2" :span="1">
                <InputText
                  type="text"
                  placeholder="ABC123"
                  name="VCOMSystemKey"
                  error-variant="tooltip"
                  :value="manualAsset?.VCOMSystemKey" />
              </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </ModalContent>

    <ModalFooterCancelAndSave :pending="isPending" :enabled="!isPending && !isLoading && isFormValid" @cancel="$emit('close')" @save="handleSave()" />
  </ModalContainer>
</template>

<style scoped lang="scss"></style>
