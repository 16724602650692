import { buildApiUrl, sendDeleteRequest, sendGetRequest, sendPostRequest, Type } from '@/features/api'
import { assertSchemaAndReturn } from '@/helpers/assert-schema'
import { z } from 'zod'
import {
  type LinkedMeterForm,
  LinkedMeterSchema,
  UnlinkedMeterSchema
} from '@/features/assets/schemas'
import { objectWithKeys, objectWithoutKeys } from '@/helpers'
import { MeterStatus, MeterType } from '@/features/assets/constants'
import { addHours } from 'date-fns/addHours'
import { addMinutes } from 'date-fns/addMinutes'
import { startOfDay } from 'date-fns/startOfDay'

export const getLinkedMeters = (assetCode: string) => sendGetRequest(
  buildApiUrl(Type.AssetManagement, `asset/${assetCode}/meters/linked`)
).then(
  ({ data }) => assertSchemaAndReturn(data, z.array(LinkedMeterSchema))
)

export const getUnlinkedMeters = (assetCode: string) => sendGetRequest(
  buildApiUrl(Type.AssetManagement, `asset/${assetCode}/meters/unlinked`)
).then(
  ({ data }) => assertSchemaAndReturn(data, z.array(UnlinkedMeterSchema))
)

export const deleteLinkedMeter = (meterId: number) => sendDeleteRequest(
  buildApiUrl(Type.AssetManagement, `asset/meter/${meterId}`)
).then(
  () => undefined
)

export const linkMeter = (assetCode: string, details: LinkedMeterForm) => {
  let requestBody: Record<string, unknown> = {
    ...objectWithoutKeys(details, [
      'commissionedAtDate',
      'commissionedAtTime',
      'decommissionedAtDate',
      'decommissionedAtTime'
    ])
  }

  if (details.meterStatus !== MeterStatus.Operational) {
    const timeSplit = details.decommissionedAtTime.split(':').map(x => parseInt(x, 10))

    requestBody.closeOutReading = details.closeOutReading
    requestBody.deCommissioning = addHours(
      addMinutes(
        startOfDay(new Date(details.decommissionedAtDate)),
        timeSplit[1]
      ),
      timeSplit[0]
    )
  }

  if (details.meterType !== MeterType.Virtual) {
    const timeSplit = details.commissionedAtTime.split(':').map(x => parseInt(x, 10))

    requestBody.commissioning = addHours(
      addMinutes(
        startOfDay(new Date(details.commissionedAtDate)),
        timeSplit[1]
      ),
      timeSplit[0]
    )
  } else {
    requestBody = objectWithKeys(requestBody, [
      'billingRegister',
      'id',
      'name',
      'deviceId',
      'meterStatus',
      'meterType',
      'description',
    ])
  }

  return sendPostRequest(
    buildApiUrl(Type.AssetManagement, `asset/${assetCode}/meter`),
    [requestBody]
  ).then(
    () => undefined
  )
}
