import { z } from 'zod'
import { ContactsSchema } from '@/features/contacts/schemas'
import {
  AssetType,
  DeliveryStatus,
  MeterBillingRegister,
  MeterBillingType,
  MeterStatus,
  MeterType
} from '@/features/assets/constants'
import { time } from '@/helpers/validation'

export type Customer = z.infer<typeof CustomerSchema>
export type AssetSettings = z.infer<typeof AssetSettingsSchema>
export type UpdateAssetSettings = z.infer<typeof UpdateAssetSettingsSchema>
export type InvoiceTemplate = z.infer<typeof InvoiceTemplatesSchema>
export type FundEntityCustomer = z.infer<typeof InvoiceCustomerSchema>
export type ContractFundEntity = z.infer<typeof ContractFundEntitySchema>
export type BillingSettings = z.infer<typeof BillingSettingsSchema>
export type LookupSettings = z.infer<typeof AssetSettingsLookupsSchema>
export type LinkedMeter = z.infer<typeof LinkedMeterSchema>
export type EditManualAsset = z.infer<typeof EditManualAssetSchema>
export type UpdateManualAsset = z.infer<typeof UpdateManualAssetSchema>
export type LinkedMeterForm = z.infer<typeof ActualMeterOperationalFormSchema | typeof ActualMeterDecommissionedFormSchema | typeof VirtualMeterFormSchema>

export const CustomerTagSchema = z.object({
  trackingCategoryReference: z.string(),
  name: z.string(),
  options: z.array(
    z.object({
      id: z.string(),
      name: z.string()
    })
  )
})

export const UnlinkedMeterSchema = z.object({
  deviceId: z.string(),
  commissioning: z.date({ coerce: true }).nullable(),
  vcomDeviceId: z.string().nullable(),
})

export const LinkedMeterSchema = z.object({
  id: z.number().int().min(1),
  meterType: z.nativeEnum(MeterType),
  deviceId: z.string(),
  serialNumber: z.string().nullable(),
  description: z.string().nullable(),
  name: z.string().nullable(),
  model: z.string().nullable(),
  meterStatus: z.nativeEnum(MeterStatus).nullable(),
  billingRegister: z.nativeEnum(MeterBillingRegister),
  commissioning: z.date({ coerce: true }).nullable(),
  takeOnReading: z.number().nullable(),
  deCommissioning: z.date({ coerce: true }).nullable(),
  closeOutReading: z.number().nullable(),
  vendor: z.string().nullable(),
  vcomDeviceId: z.string().nullable(),
})

const actualMeterBaseShape = z.object({
  meterType: z.union([z.literal(MeterType.Generator), z.literal(MeterType.Solar), z.literal(MeterType.Grid)]),

  id: z.number().optional(),
  name: z.string().min(1),
  deviceId: z.string(),
  serialNumber: z.string().min(1),
  model: z.string().min(1),
  billingRegister: z.nativeEnum(MeterBillingRegister),
  commissionedAtDate: z.date(),
  commissionedAtTime: time(),
  takeOnReading: z.number(),
  vendor: z.string().min(1),
  vcomDeviceId: z.string().min(1),
  description: z.string().nullable(),
})

export const ActualMeterOperationalFormSchema = actualMeterBaseShape.merge(
  z.object({
    meterStatus: z.literal(MeterStatus.Operational)
  })
)

export const ActualMeterDecommissionedFormSchema = actualMeterBaseShape.merge(
  z.object({
    meterStatus: z.literal(MeterStatus.Decommissioned),
    decommissionedAtDate: z.date(),
    decommissionedAtTime: time(),
    closeOutReading: z.number(),
  })
)

export const VirtualMeterFormSchema = actualMeterBaseShape.pick({
  id: true,
  billingRegister: true,
  name: true,
  deviceId: true,
  description: true,
}).merge(
  z.object({
    meterType: z.literal(MeterType.Virtual),
    meterStatus: z.literal(MeterStatus.Operational),
  })
)

export const ProjectDetailsSchema = z.object({
  code: z.number(),
  name: z.string(),
  customerId: z.string(),
  poNumber: z.string().nullable(),
  vatNumber: z.string().nullable(),
  addressLine1: z.string().nullable(),
  addressLine2: z.string().nullable(),
  city: z.string().nullable(),
  suburb: z.string().nullable(),
  countryId: z.number().nullable(),
})

export const AssetDetailsSchema = z.object({
  code: z.string(),
  projectCode: z.number(),
  projectName: z.string(),
  type: z.nativeEnum(AssetType),
  productId: z.number(),
  deliveryStatus: z.nativeEnum(DeliveryStatus),
  cumulativeYearlySavings: z.number(),
  systemSize: z.number(),
  consumptionYield: z.number(),
})

export const CustomerSchema = z.object({
  id: z.string(),
  name: z.string()
})

export const ProjectSchema = z.object({
  id: z.number().int().gte(0),
  organisationId: z.number().int().gt(0),
  projectCode: z.number().int().gt(0),
  customerExternalIdentifier: z.string(),
  poNumber: z.string(),
  utilityProviderId: z.number().int().gt(0),
  name: z.string(),
  description: z.string(),
  addressLineOne: z.string().nullish(),
  addressLineTwo: z.string().nullish(),
  suburb: z.string().nullish(),
  city: z.string().nullish(),
  countryId: z.string(),
  vatNo: z.string().nullish(),
  assets: z.array(z.object({
    projectCode: z.number().int().gt(0),
    assetCode: z.string(),
    assetType: z.string(), //Enum
    degradationTypeId: z.number().int().gt(0),
    productId: z.number().int().gt(0),
    cumulativeYearSavings: z.number(),
    systemSize: z.number(),
    consumptionYield: z.number(),
    contract: z.object({
      id: z.number().int().gt(0),
      contractStartDate: z.date(),
      fundTransferDate: z.date(),
      annualEscalationType: z.string(),
      fundEntity: z.string(),
      contractLength: z.number().int().gt(0),
      buyoutYear: z.number().int().gt(0),
      blendedTariff: z.number(),
      epcPrice: z.number(),
      drawerNumber: z.number(),
    }).optional().nullable(),
    demandSolarConsumedLine: z.array(z.object({
      assetId: z.number().int().gt(0),
      date: z.coerce.date(),
      customBenchmark: z.number().gte(0),
      demandHourlyCons: z.number().gte(0),
      solarHourlyCons: z.number().gte(0),
      consumedHourlyCons: z.number().gte(0),
      tariffType: z.string(),
      utilityRate: z.number()
    })).optional().nullable(),
    meters: z.array(z.object({
      id: z.number().int().gt(0),
      meterType: z.string(),
      deviceId: z.string(),
      serialNumber: z.string(),
      assetCode: z.string(),
      description: z.string(),
      name: z.string(),
      model: z.string(),
      meterStatus: z.string(),
      billingRegister: z.nativeEnum(MeterBillingRegister),
      commissioning: z.coerce.date(),
      takeOnReading: z.number(),
      deCommissioning: z.coerce.date(),
      closeOutReading: z.number(),
      vendor: z.string(),
      vcomDeviceId: z.string()
    })).nullable(),
    allMeters: z.array(z.object({
      id: z.number().int().gt(0),
      meterType: z.string(),
      deviceId: z.string(),
      serialNumber: z.string(),
      assetCode: z.string(),
      description: z.string(),
      name: z.string(),
      model: z.string(),
      meterStatus: z.string(),
      billingRegister: z.nativeEnum(MeterBillingRegister),
      commissioning: z.coerce.date(),
      takeOnReading: z.number(),
      deCommissioning: z.coerce.date(),
      closeOutReading: z.number(),
      vendor: z.string(),
      vcomDeviceId: z.string()
    })).optional().nullable(),
    performanceMeasures: z.array(z.object({
      id: z.number().int().gt(0),
      assetCode: z.string(),
      performanceMeasureType: z.string(),
      benchmarkType: z.string(),
      name: z.string(),
      startDate: z.date(),
      endDate: z.date(),
      noEndDate: z.boolean(),
      performancePercentage: z.number(),
      benchmarkPercentage: z.number(),
      performanceMeasurePercentage: z.number(),
      isDeleted: z.boolean(),
      templateGenerated: z.boolean(),
    })).optional().nullable()
  })),
  contacts: z.array(z.object({
    id: z.number().gt(0),
    contactTypeId: z.number().gt(0),
    firstname: z.string(),
    surname: z.string(),
    street: z.string().nullish(),
    suburb: z.string().nullish(),
    city: z.string().nullish(),
    countryId: z.number().int(),
    primaryContact: z.boolean(),
    secondaryContact: z.boolean(),
    linked: z.boolean()
  })).optional().nullable()
})


export const ContractFundEntitySchema = z.object({
  id: z.number().default(0),
  name: z.string().default(''),
  registrationNumber: z.string().nullable().default(''),
  address: z.string().nullable().default(''),
  vatNumber: z.string().nullable().default(''),
  xeroInvoiceAccountNumber: z.string().nullable().default(''),
  contactEmailAddress: z.string().nullable().default(''),
  contactTelephoneNumber: z.string().nullable().default('')
})

export const InvoiceCustomerSchema = z.object({
  customerExternalIdentifier: z.string().nullable().default(''),
  name: z.string().nullable().default(''),
  addressLineOne: z.string().nullable().default(''),
  addressLineTwo: z.string().nullable().default(''),
  suburb: z.string().nullable().default(''),
  city: z.string().nullable().default(''),
  region: z.string().nullable().default(''),
  country: z.string().nullable().default(''),
  vatNumber: z.string().nullable().default('')
})

export const BillingSettingsSchema = z.object({
  customerTag: z.string().nullable(),
  meterBillingType: z.nativeEnum(MeterBillingType),
  contractStartDate: z.coerce.date().nullable(),
  fundTransferDate: z.coerce.date().nullable(),
  billingCycle: z.object({
    id: z.number().int().default(0),
    name: z.string().default(''),
    description: z.string().default(''),
  }).nullable().default({}),
  paymentDueInDays: z.number().int().gte(0).default(1),
  invoiceDate: z.number().default(0),
  firstEscalationMonth: z.coerce.date().nullable().default(new Date),
  autoApprove: z.boolean().default(false),
  expectedEnergyVariance: z.number().default(0),
  gridExportValidation: z.boolean().default(false),
  exportPower: z.boolean().default(false),
  exportPowerTariffInCents: z.number().default(0),
  invoiceDateFormat: z.string().nullable()
})

export const UpdateBillingSettingsSchema = z.object({
  customerTag: z.string().nullable(),
  meterBillingType: z.nativeEnum(MeterBillingType),
  contractStartDate: z.coerce.date().nullable(),
  fundTransferDate: z.coerce.date().nullable(),
  billingCycleId: z.number().default(1),
  paymentDueInDays: z.number().int().gte(0).default(1),
  invoiceDate: z.number().default(0),
  firstEscalationMonth: z.coerce.date().nullable().default(new Date),
  autoApprove: z.boolean().default(false),
  expectedEnergyVariance: z.number().default(0),
  gridExportValidation: z.boolean().default(false),
  exportPower: z.boolean().default(false),
  exportPowerTariffInCents: z.number().default(0),
  invoiceDateFormat: z.string().nullable()
})

export const ContractTermsSchema = z.object({
  assetDrawerNumber: z.number().nullish(),
  annualEscalation: z.object({
    id: z.number().int().default(0),
    name: z.string().default(''),
  }).nullish().default({}),
  contractLengthInMonths: z.number().default(0),
  degradationType: z.number().default(0)
})

export const UtilityProviderSchema = z.object({
  blendedTariffInCents: z.number(),
  utilityProviderId: z.number().int().gt(0).nullable(),
  tariffStructureId: z.number().int().gt(0).nullable(),
})

export const BillingRateSchema = z.object({
  id: z.number(),
  tariffTypeId: z.number(),
  seasonId: z.number().nullable(),
  timeOfUseDemandTypeId: z.number().nullable(),
  rate: z.number().optional(),
  exportPowerInCents: z.number().nullable().optional()
})

export const TariffProfileSchema = z.object({
  tariffStructureId: z.number().default(0),
  exportPowerEscalationTypeId: z.number().nullable().default(0),
  isExportPower: z.boolean().default(false),
  exportPowerEscValue: z.number().default(0),
  rate: z.number().optional().default(0),
  exportPowerInCents: z.number().optional().default(0),
  billingRate: z.array(BillingRateSchema).default([])
})



export const InvoiceTemplatesSchema = z.object({
  id: z.number().int(),
  documentId: z.number(),
  description: z.string(),
  active: z.boolean(),
  document: z.object({
    id: z.number().int(),
    fileFormat: z.string(),
    documentType: z.string(),
    contentAsBase64String: z.string(),
    createDateTime: z.date({ coerce: true }),
    updateDateTime: z.date({ coerce: true })
  }).nullable()
})
export const AssetSettingsSchema = z.object({
  assetCode: z.string().default('0'),
  projectCode: z.number().default(0),
  projectName: z.string().optional().default(''),
  deliveryStatus: z.object({
    id: z.number().int().gt(0).default(1),
    name: z.string().default('')
  }).default({}),
  invoiceDetails: z.object({
    contractFundEntity: ContractFundEntitySchema.nullable().default({}),
    customer: InvoiceCustomerSchema.nullable().default({}),
  }).default({}),
  billingSettings: BillingSettingsSchema,
  contractTerms: ContractTermsSchema,
  utilityProvider: UtilityProviderSchema.nullable(),
  contacts: z.array(ContactsSchema).nullable().default([]),
  invoiceTemplates: z.array(InvoiceTemplatesSchema).nullable().default([]),
})

export const UpdateAssetSettingsSchema = z.object({
  assetCode: z.string().default('0'),
  invoiceDetails: z.object({
    contractFundEntityId: z.number().gt(0),
    customerExternalIdentifier: z.string().nullable().default('')
  }),
  billingSettings: UpdateBillingSettingsSchema,
  contractTerms: z.object({
    assetDrawerNumber: z.number().nullish(),
    degradationType: z.number().default(0)
  }),
  utilityProvider: z.object({
    utilityProviderId: z.number().int().gt(0),
    tariffStructureId: z.number().int().gt(0),
    blendedTariff: z.number(),
  }),
  invoiceTemplates: z.array(InvoiceTemplatesSchema).nullable().default([])
})

export const AssetSettingsLookupsSchema = z.object({
  contractFundEntities: z.array(ContractFundEntitySchema),
  countries: z.array(z.object({
    code: z.number(),
    id: z.number(),
    name: z.string()
  })).default([]),
  degradationTypes: z.array(z.object({
    id: z.number(),
    name: z.string()
  })),
  tariffStructures: z.array(z.object({
    description: z.string().nullable(),
    id: z.number(),
    name: z.string(),
    tariffTypes: z.array(z.object({
      tariffStructureId: z.number(),
      id: z.number(),
      name: z.string()
    })),
  })),
  billingCycles: z.array(z.object({
    description: z.string(),
    id: z.number(),
    name: z.string()
  })),
  deliveryStatuses: z.array(z.object({
    id: z.number(),
    name: z.string()
  })),
  invoiceDateFormats: z.array(z.object({
    id: z.number(),
    name: z.string()
  }))
})
// Reusable Schema Definitions
const VueUISelectSchema = z.object({
  value: z.string().default(""),
  label: z.string().default(""),
});

const CountrySchema = z.object({
  CountryId: z.number().default(0),
  Name: z.string().default(""),
  CPI: z.number().default(0),
  UtilityInflation: z.number().default(0),
  Azimuth: z.number().default(0),
  Tilt: z.number().default(0),
  CorporateTaxRate: z.number(),
  Currency: z.object({
    Id: z.number().default(0),
    Code: z.string().default(""),
    Symbol: z.string().default(""),
    Description: z.string().default(""),
  }),
  CurrencyId: z.number().default(0),
  MapConfigId: z.number().default(0),
  MapConfig: z.object({
    Id: z.number().default(0),
    Name: z.string().default(""),
    Latitude: z.number().default(0),
    Longitude: z.number().default(0),
    ZoomLevel: z.number().default(0),
    GeocodeName: z.string().default(""),
    HasRegions: z.boolean().default(false),
  }),
});

// Default object for ProjectLeadSchema
const defaultProjectLead = {
  ProjectLeadId: 0,
  ProjectName: "",
  SiteAddressLine1: "",
  SiteAddressLine2: "",
  SiteAddressSuburb: "",
  SiteAddressCity: "",
  ProvinceId: 0,
  Province: {
    ProvinceId: 0,
    ProvinceDescription: "",
    CountryId: 0,
    Country: {
      CountryId: 0,
      Name: "",
      CPI: 0,
      UtilityInflation: 0,
      Azimuth: 0,
      Tilt: 0,
      CorporateTaxRate: 0,
      Currency: {
        Id: 0,
        Code: "",
        Symbol: "",
        Description: "",
      },
      CurrencyId: 0,
      MapConfigId: 0,
      MapConfig: {
        Id: 0,
        Name: "",
        Latitude: 0,
        Longitude: 0,
        ZoomLevel: 0,
        GeocodeName: "",
        HasRegions: false,
      },
    },
  },
  CountryId: 0,
  SitePostalCode: "",
  SiteGpsCoordinates: "",
  SiteMapZoomLevel: 0,
  DateTimeCreated: "",
  Azimuth: 0,
  Tilt: 0,
  SiteUtilityProviderId: 0,
  SiteUtilityProviderStructureId: 0,
  ProjectLeadGpsCoordinates: [],
  SiteSolarYield: 0,
  SystemLoss: 0,
  UseUniFiiYield: false,
  ProjectLeadStatusId: 0,
  ProjectLeadStatus: "",
  LeadOwnerUserId: "",
  LeadAssignedToUserId: "",
  PreviousLeadOwnerUserId: "",
  ChangingAssignedUserId: "",
  ChangingOwnerUserId: "",
  LeadAssignedToUser: {
    Salutation: "",
    FirstName: "",
    LastName: "",
    UserType: "",
    Company: "",
    Designation: "",
    Address_Street: "",
    Address_City: "",
    Address_Country: "",
    CreatedOn: "",
    IsFinancedApproved: null,
    OrganizationId: 0,
    UserStatus: 0,
    Password: "",
    UpdatedOn: "",
    UserName: "",
    Email: "",
    AccessAreaRightsViewModels: null,
    Id: "",
    SelectedUserProfileIds: [],
    FullName: "",
    PhoneNumber: "",
    UserProfiles: [],
    LastLoginDateTime: "",
    EmailConfirmed: false,
    ImageUrl: "",
    Claims: [],
  },
};

const ProjectLeadSchema = z.object({
  ProjectLeadId: z.number().default(0),
  ProjectName: z.string().default(''),
  SiteAddressLine1: z.string().nullable().default(""),
  SiteAddressLine2: z.string().nullable().default(""),
  SiteAddressSuburb: z.string().nullable().default(""),
  SiteAddressCity: z.string().nullable().default(""),
  ProvinceId: z.number().default(0),
  Province: z.object({
    ProvinceId: z.number().default(0),
    ProvinceDescription: z.string(),
    CountryId: z.number().default(0),
    Country: CountrySchema,
  }),
  CountryId: z.number().default(0),
  SitePostalCode: z.string().nullable().default(""),
  SiteGpsCoordinates: z.string().nullable().default(""),
  SiteMapZoomLevel: z.number().default(0),
  DateTimeCreated: z.string().default(""),
  Azimuth: z.number().nullable().default(0),
  Tilt: z.number().nullable().default(0),
  SiteUtilityProviderId: z.number().nullable().default(0),
  SiteUtilityProviderStructureId: z.number().nullable().default(0),
  ProjectLeadGpsCoordinates: z.array(z.unknown().default([])),
  SiteSolarYield: z.number().nullable().default(0),
  SystemLoss: z.number().default(0),
  UseUniFiiYield: z.boolean().default(false),
  ProjectLeadStatusId: z.number().default(0),
  ProjectLeadStatus: z.string().nullable().default(""),
  LeadOwnerUserId: z.string().default(""),
  LeadAssignedToUserId: z.string().default(""),
  PreviousLeadOwnerUserId: z.string().nullable().default(""),
  ChangingAssignedUserId: z.string().nullable().default(""),
  ChangingOwnerUserId: z.string().nullable().default(""),
  LeadAssignedToUser: z.object({
    Salutation: z.string().nullable().default(""),
    FirstName: z.string().default(""),
    LastName: z.string().default(""),
    UserType: z.string().nullable().default(""),
    Company: z.string().nullable().default(""),
    Designation: z.string().nullable().default(""),
    Address_Street: z.string().nullable().default(""),
    Address_City: z.string().nullable().default(""),
    Address_Country: z.string().nullable().default(""),
    CreatedOn: z.string().default(""),
    IsFinancedApproved: z.boolean().nullable(),
    OrganizationId: z.number().default(0),
    UserStatus: z.number().default(0),
    Password: z.string().nullable().default(""),
    UpdatedOn: z.string().nullable().default(""),
    UserName: z.string().default(""),
    Email: z.string().default(""),
    AccessAreaRightsViewModels: z.array(z.unknown().default([])).nullable(),
    Id: z.string().default(""),
    SelectedUserProfileIds: z.array(z.unknown().default([])).nullable().default([]),
    FullName: z.string().default(""),
    PhoneNumber: z.string().nullable().default(""),
    UserProfiles: z.array(z.unknown()).nullable().default([]),
    LastLoginDateTime: z.string().default(""),
    EmailConfirmed: z.boolean().default(false),
    ImageUrl: z.string().nullable().default(""),
    Claims: z.array(z.string().default("")),
  }),
});

export const EditManualAssetSchema = z.object({
  Id: z.number().int().default(0),
  Country: z.string().default(""),
  VatNo: z.string().nullable().default(""),
  SystemSize: z.number().default(0),
  ConsumptionYield: z.number().default(0),
  EscalationValueOffSet: z.number().default(0),
  OrganisationId: z.number().default(0),
  SolutionId: z.number().nullable().default(0),
  ProductId: z.number().default(0),
  ContractingPartyId: z.number().default(0),
  TariffEscalationId: z.number().default(0),
  VCOMSystemKeyId: z.number().nullable().default(0),
  VCOMSystemKey: z.string().nullable().default(""),
  ProjectLead: ProjectLeadSchema.default(defaultProjectLead),
  SolutionTypes: z.array(VueUISelectSchema).default([]),
  VCOMSystemKeys: z.array(VueUISelectSchema).nullable().default([]).nullable(),
  Products: z.array(VueUISelectSchema).default([]),
  ContractFundEntities: z.array(VueUISelectSchema).default([]),
  TariffEscalationTypes: z.array(VueUISelectSchema).default([])
})

export const UpdateManualAssetSchema = z.object({
  Id: z.number().int(),
  Country: z.string(),
  VatNo: z.string(),
  SystemSize: z.number(),
  ConsumptionYield: z.number(),
  EscalationValueOffSet: z.number(),
  OrganisationId: z.number().int(),
  SolutionId: z.number().int(),
  ProductId: z.number().int(),
  ContractingPartyId: z.number().int(),
  TariffEscalationId: z.number().int(),
  VCOMSystemKeyId: z.number().int(),
  VCOMSystemKey: z.string()
})
