import { type MaybeRefOrGetter, toValue } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { deleteLinkedMeter, getLinkedMeters, getUnlinkedMeters, linkMeter } from '@/features/assets/meters'
import { createLogger } from '@/helpers/logging'

export const useLinkedMeters = (assetCode: MaybeRefOrGetter<string|undefined>) => useQuery({
  queryKey: ['assets', 'meters', 'linked', assetCode],
  meta: {
    logger: createLogger('composables/assets/useLinkedMeters')
  },
  enabled() {
    const reducedValue = toValue(assetCode)

    return reducedValue !== undefined 
  },
  queryFn: () => getLinkedMeters(toValue(assetCode)!)
})

export const useUnlinkedMeters = (assetCode: MaybeRefOrGetter<string>) => useQuery({
  queryKey: ['assets', 'meters', 'unlinked', assetCode],
  meta: {
    logger: createLogger('composables/assets/useUnlinkedMeters')
  },
  queryFn: () => getUnlinkedMeters(toValue(assetCode))
})

export function useDeleteMeter() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (meterId: MaybeRefOrGetter<number>) => deleteLinkedMeter(toValue(meterId)),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ['assets', 'meters'] })
    },
  })
}

export function useLinkMeter(assetCode: MaybeRefOrGetter<string>) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (meter: Parameters<typeof linkMeter>[1]) => linkMeter(toValue(assetCode), meter),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ['assets', 'meters'] })
    }
  })
}
