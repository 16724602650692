<script setup lang="ts">
import { useAssetSettings } from '@/composables/assets/assets';
import { computed, defineProps, ref, watch } from 'vue'
import CardContainer from "@/components/Card/card-container.vue";
import CardContent from "@/components/Card/card-content.vue";
import CardHeader from "@/components/Card/card-header.vue";
import CardHeaderItem from '@/components/Card/card-header-item.vue';
import InputLabel from "@/components/input-label.vue";
import GridContainer from "@/components/grid-container.vue";
import InputSelect from '@/components/input-select.vue'
import { useUtilities } from '@/composables/utilities/utilities'
import { useUtilityTariffs } from '@/composables/utilities/tariffs'
import DataTable from '@/components/data-table.vue'
import DataTableHeader from '@/components/data-table-header.vue'
import DataTableCell from '@/components/data-table-cell.vue'
import FlexContainer from '@/components/flex-container.vue'
import InputCurrency from '@/components/input-currency.vue'
import { roundToMainCurrencyPrecision } from '@/helpers'
import { useFieldValue } from 'vee-validate'
import InputNumber from '@/components/input-number.vue'

const props = defineProps<{ assetCode: string }>();

const utilityProviderId = useFieldValue<number>('utilityProvider.utilityProviderId')
const tariffStructureId = useFieldValue<number>('utilityProvider.tariffStructureId')

const selectedTariff = computed(() => (tariffs.value || []).find(x => x.id === tariffStructureId.value))
const additionalCharges = computed(() => selectedTariff.value?.additionalCharges || [])
const tariffSeasons = computed(
  () => {
    const seasons: Record<string, NonNullable<typeof selectedTariff.value>['bands']> = { }

    selectedTariff.value?.bands.forEach(
      band => {
        if (band.seasonName in seasons) {
          seasons[band.seasonName].push(band)
        } else {
          seasons[band.seasonName] = [band]
        }
      }
    )

    return seasons
  }
)

const { data: assetData, isLoading: assetIsLoading } = useAssetSettings(props.assetCode)
const { data: utilities, isError: hasUtilitiesError, isLoading: utilitiesAreLoading } = useUtilities()
const { data: tariffs, isError: hasTariffsError, isLoading: tariffsAreLoading } = useUtilityTariffs(utilityProviderId)
</script>
<template>
    <CardContainer :class="$style['utility-profile']">
        <CardHeader>
            <CardHeaderItem>Utility Profile</CardHeaderItem>
        </CardHeader>
        <CardContent>
            <GridContainer :columns="['max-content', 300]" :column-gap="13" align="center">
              <InputLabel>Blended Tariff</InputLabel>
              <div>
                <InputCurrency name="utilityProvider.blendedTariff"
                               inline
                               required
                               style="width: 101px"
                               error-variant="tooltip"
                               :value="
                                typeof assetData?.utilityProvider?.blendedTariffInCents === 'number'
                                  ? roundToMainCurrencyPrecision(assetData.utilityProvider.blendedTariffInCents / 100)
                                  : assetData?.utilityProvider?.blendedTariffInCents
                               " />
                <span class="ms-2 text-muted">/ kWh</span>
              </div>

              <InputLabel>Utility</InputLabel>
              <InputSelect :items="(utilities || []).map(x => ({ text: x.name, value: x.id }))"
                           name="utilityProvider.utilityProviderId"
                           :placeholder="hasUtilitiesError ? 'Error loading utilities.' : (utilitiesAreLoading || assetIsLoading ? 'Loading...' : undefined)"
                           :selected="assetData?.utilityProvider?.utilityProviderId"
                           error-variant="tooltip"
                           item-text="text"
                           item-value="value" />

              <InputLabel>Tariff Structure</InputLabel>
              <InputSelect :items="(tariffs || []).map(x => ({ text: x.name, value: x.id }))"
                           name="utilityProvider.tariffStructureId"
                           error-variant="tooltip"
                           :selected="assetData?.utilityProvider?.tariffStructureId"
                           :placeholder="hasTariffsError ? 'Error loading utilities' : (tariffsAreLoading ? 'Loading...' : (utilityProviderId ? undefined : 'Please select a utility.'))"
                           :disabled="!tariffs || tariffsAreLoading"
                           item-text="text"
                           item-value="value" />
            </GridContainer>

            <FlexContainer align="start" direction="row" :class="$style['bands-container']" v-if="selectedTariff">
              <CardContainer header-placement="outside" :key="season" v-for="(bands, season) in tariffSeasons" style="min-width: 420px">
                <CardHeader>
                  Tariff Bands
                  <template v-if="season.toUpperCase() !== 'N/A'">- {{ season }}</template>
                  ({{ selectedTariff.year }}/{{ selectedTariff.year + 1 }})
                </CardHeader>
                <CardContent>
                  <DataTable :records="bands" class="mb-0">
                    <template #headers>
                      <DataTableHeader>Description</DataTableHeader>
                      <DataTableHeader>Start of Band</DataTableHeader>
                      <DataTableHeader>End of Band</DataTableHeader>
                      <DataTableHeader>Band Rate (cents)</DataTableHeader>
                    </template>
                    <template #record="{ record }">
                      <DataTableCell>{{ record.name }}</DataTableCell>
                      <DataTableCell>{{ record.rangeStart }}</DataTableCell>
                      <DataTableCell>{{ record.rangeEnd === -1 ? '-' : record.rangeEnd }}</DataTableCell>
                      <DataTableCell>{{ record.rateInCents }}</DataTableCell>
                    </template>
                  </DataTable>
                </CardContent>
              </CardContainer>

              <CardContainer header-placement="outside" v-if="additionalCharges.length > 0" style="min-width: 255px" :open="true">
                <CardHeader>Additional Charges</CardHeader>
                <CardContent>
                  <DataTable :records="additionalCharges" class="mb-0">
                    <template #headers>
                      <DataTableHeader>Description</DataTableHeader>
                      <DataTableHeader>Rate (cents)</DataTableHeader>
                    </template>
                    <template #record="{ record }">
                      <DataTableCell>{{ record.name }}</DataTableCell>
                      <DataTableCell>{{ record.rateInCents }}</DataTableCell>
                    </template>
                  </DataTable>
                </CardContent>
              </CardContainer>
            </FlexContainer>
        </CardContent>
    </CardContainer>
</template>
<style module lang="scss">
.utility-profile {
    min-height: 129px;
}

.bands-container {
  margin-top: 30px;
}
</style>
