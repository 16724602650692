import { buildApiUrl, Type, sendPutRequest } from '../api'
import { type UpdateAssetSettings } from './schemas'
import { objectWithoutKeys, roundToFractionalCurrencyPrecision } from '@/helpers'

export async function saveAssetSettings(assetCode: string, settings: UpdateAssetSettings) {
  const url = buildApiUrl(Type.AssetManagement, `asset/${assetCode}/settings`)

  await sendPutRequest(url, {
    data: {
      ...settings,
      utilityProvider: {
        ...objectWithoutKeys(settings.utilityProvider, ['blendedTariff']),
        blendedTariffInCents: roundToFractionalCurrencyPrecision(settings.utilityProvider.blendedTariff * 100)
      }
    }
  })
}
