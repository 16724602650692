import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { type MaybeRefOrGetter, toValue } from 'vue'
import { getContactTypes, getContacts } from '@/features/contacts/get-contacts'
import { addContact, deleteContact, updateContact, updateContacts } from '@/features/contacts/save-contacts'
import type { Contact } from '@/features/contacts/schemas'


export function useContactTypes() {
    return useQuery({
        queryKey: ['contactTypes'],
        queryFn: () =>
            getContactTypes()
    })
}

export function useContacts(assetCode: MaybeRefOrGetter<string | undefined>, contactType: number) {
    return useQuery({
        queryKey: ['contacts', assetCode],
        queryFn: () =>
            getContacts(toValue(assetCode)!, contactType),
        enabled() {
            const value = toValue(assetCode)

            return value !== undefined
        }
    })
}

export function useAddContact(assetCode: string) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (contact: MaybeRefOrGetter<Contact>) => addContact(assetCode,toValue(contact)),        
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['contacts'] })
        },
    })
}
export function useDeleteContacts() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (contactId: number) => deleteContact(contactId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['contacts'] })
        }
    })
}

export function useUpdateContact() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (contact: MaybeRefOrGetter<Contact>) => updateContact(toValue(contact)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['contacts'] })
        },
    })
}
export function useUpdateContacts(assetCode: MaybeRefOrGetter<string | undefined>) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (contact: MaybeRefOrGetter<Contact[]>) => updateContacts(toValue(assetCode)!,toValue(contact)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['contacts'] })
        },
    })
}



